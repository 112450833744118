import { useEffect, useState } from "react";

export type Prices = { [symbol: string]: number };

export const usePrices = () => {
  const [prices, setPrices] = useState<Prices>();

  useEffect(() => {
    fetch("https://u--1.s3.us-east-2.amazonaws.com/coin-prices.json")
      .then((response) => response.json())
      .then((result: { value: { symbol: string; usd_price: number }[] }) => {
        const newPrices = Object.values(result.value).reduce(
          (acc, { symbol, usd_price }) => {
            acc[symbol] = usd_price;
            return acc;
          },
          {} as { [symbol: string]: number }
        );

        setPrices(newPrices);
      });
  }, []);

  return { prices };
};
