import { Prices } from "../Context/usePrices";
import { Market } from "../Model/Market";
import { Reserve } from "../Model/Reserve";

export const getReserveUsdPrice = (args: {
  market: Market;
  reserve: Reserve;
  ethUsdPrice: number;
  prices: Prices | undefined;
}) => {
  const { market, reserve } = args;

  const { baseDecimals, baseCurrency } = market;

  const { priceInEth } = reserve.price;

  const assetPrice = (1 / 10 ** baseDecimals) * priceInEth;

  const usdPrice =
    args.prices?.[reserve.symbol] ||
    (baseCurrency === "usd"
      ? assetPrice
      : args.ethUsdPrice
      ? args.ethUsdPrice * assetPrice
      : undefined);

  return { usdPrice };
};
