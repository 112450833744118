import { Market } from "../../Model/Market";
import { MARKETS } from "../../Core/markets";
import { TsHelmet } from "../../Components/TsHelmet";

export const HomePageHelmet = (props: { market: Market }) => {
  const { market } = props;

  const canonical =
    market.id === MARKETS[0].id
      ? "https://aavescan.com"
      : `https://aavescan.com/${market.id}`;

  const title =
    market.id === MARKETS[0].id
      ? "Aavescan | Live and historical rates for Aave"
      : `Aavescan | ${market.name}, live and historical rates for Aave`;

  const description = `Aavescan shows live and historical Aave lending data, including deposit and borrow rates, for all markets including ${market.name}.`;

  return (
    <TsHelmet>
      <title>{title}</title>

      {/* Basic meta tags */}
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* Open Graph (OG) meta tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonical} />
      {/* Assuming you have a default image to show on social media */}

      {/* Twitter card meta tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      <link rel="canonical" href={canonical} />
    </TsHelmet>
  );
};
