import { ApolloError } from "@apollo/client";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { AssetImage } from "../../Components/AssetImage";
import { Container } from "../../Components/Container";
import { Rates } from "../../Core/rates";
import { Market } from "../../Model/Market";
import { RateId } from "../../Model/Rates";
import { useDownloadReserveListCsv } from "./hooks/useDownloadReserveListCsv";
import { useReserves } from "./hooks/useReserves";

const LIVE_RATES = Rates.filter((rate) => rate.isLive);

export const HomePageTable = (props: {
  error: ApolloError | undefined;
  loading: boolean;
  market: Market;
  orderBy: string;
  orderDirection: string;
  refetch: () => void;
  reserves: ReturnType<typeof useReserves>["reserves"];
  setOrderBy: (orderBy: RateId) => void;
  toggleOrderDirection: () => void;
}) => {
  const {
    error,
    loading,
    market,
    orderBy,
    orderDirection,
    refetch,
    reserves,
    setOrderBy,
    toggleOrderDirection,
  } = props;

  const downloadCsv = useDownloadReserveListCsv({ reserves, market });

  return (
    <div style={{ minHeight: "90vh" }}>
      <Container
        loading={loading}
        spinner
        error={error}
        refetch={() => refetch()}
        minHeight={100}
      >
        {!loading && !error && (
          <>
            <div style={{ overflow: "auto" }}>
              <table className="fade-in">
                <caption>Live Aave rates for {market.chain}.</caption>
                <thead className="non-select">
                  <tr>
                    <th title="reserve" key="reserve">
                      <div>Reserve</div>
                    </th>
                    {LIVE_RATES.map((rate) => (
                      <th
                        title={rate.liveDescription || rate.label}
                        key={rate.label}
                        onClick={() => {
                          if (orderBy === rate.id) {
                            toggleOrderDirection();
                          } else {
                            setOrderBy(rate.id);
                          }
                        }}
                      >
                        <div className="ClickableHeader">
                          {rate.shortLabel}
                          {orderBy === rate.id && (
                            <span
                              className="ClickableHeader_sort"
                              style={{
                                transform:
                                  orderDirection === "asc"
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              }}
                            >
                              ▼
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {reserves?.map(({ reserve, rates }) => (
                    <tr key={reserve.id}>
                      <td title={reserve.name}>
                        <Link to={`/${market.id}/${reserve.id}`}>
                          <div
                            className="flex"
                            style={{ alignItems: "center" }}
                          >
                            <AssetImage symbol={reserve.symbol} size={14} />
                            <span className="ml4">{reserve.symbol}</span>
                          </div>
                        </Link>
                      </td>
                      {rates.map(({ rate, value }) => {
                        return (
                          <td key={reserve.id + rate.label}>
                            {rate.toShortString(Math.max(value, 0))}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-end my8">
              <button onClick={downloadCsv}>
                <span>Download CSV</span>
                <FontAwesomeIcon icon={faDownload} />
              </button>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};
