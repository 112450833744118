import { useEffect, useState } from "react";

const url = "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD";

export const useEthPrice = () => {
  const [ethUsdPrice, setEthUsdPrice] = useState<number>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const load = async () => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        setEthUsdPrice(data["USD"]);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
        setEthUsdPrice(1000);
        console.error("Failed to fetch ETH price. Defaulting to $1000.");
      }
    };

    load();
  }, []);

  return { ethUsdPrice, loading };
};
