import { COIN_SYMBOL_TO_CMC_ID } from "../../Config/constants";

export const AssetImage = (props: { symbol: string; size?: number }) => {
  const standardisedSymbol = props.symbol.replace(".e", "").replace(".b", "");
  const cmcId = Object(COIN_SYMBOL_TO_CMC_ID).hasOwnProperty(standardisedSymbol)
    ? COIN_SYMBOL_TO_CMC_ID[standardisedSymbol as "WBTC"]
    : 1027;

  return (
    <img
      height={(props.size || 12) + "px"}
      width={(props.size || 12) + "px"}
      src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${cmcId}.png`}
      alt={props.symbol}
    />
  );
};
