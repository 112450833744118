import { useEffect, useState } from "react";
import { Line } from "../../../Model/graph";
import { Market } from "../../../Model/Market";
import { Rate } from "../../../Model/Rates";
import { Reserve } from "../../../Model/Reserve";
import { ReserveParamsHistoryItem } from "../../../Model/ReserveParamsHistoryItem";
import { useReserveHistoryQuery } from "./useReserveHistoryQuery";

export const useReserveHistoryData = (args: {
  selectedReserve: Reserve | undefined;
  selectedReserveId: string;
  selectedRate: Rate;
  historyQuery: ReturnType<typeof useReserveHistoryQuery>;
  market: Market;
}) => {
  const {
    selectedReserve,
    selectedReserveId,
    selectedRate,
    historyQuery,
    market,
  } = args;

  const [anchorValue, setAnchorValue] = useState<ReserveParamsHistoryItem>();
  const [line, setLine] = useState<Line>();

  /*
   * Recalculate line sparingly
   */
  useEffect(() => {
    if (historyQuery.loading) {
      setLine(undefined);
      setAnchorValue(undefined);
    }

    if (selectedReserve && historyQuery.data) {
      const items = Object.values(historyQuery.data).reduce(
        (acc, val) => acc.concat(val),
        [] as ReserveParamsHistoryItem[]
      );

      const firstItem = items[0];
      const usdDecimals = market.baseDecimals === 18 ? 0 : market.baseDecimals;

      const values = items.map((rate) => ({
        unix: rate.timestamp * 1000,
        amount: selectedRate.rescale(rate[selectedRate.value], {
          reserve: selectedReserve,
          usdPrice: rate.priceInUsd / 10 ** usdDecimals,
          anchorValue: firstItem[selectedRate.value],
        }),
      }));

      setAnchorValue(firstItem);

      setLine({
        color: "var(--chart-color)",
        shaded: true,
        values,
        uniqueId: selectedReserveId,
      });
    }
  }, [
    historyQuery.data,
    historyQuery.loading,
    selectedRate,
    selectedReserve,
    selectedReserveId,
    market,
  ]);

  return { anchorValue, line };
};
