import { useQuery } from "@apollo/client";
import { useState } from "react";
import { Rates } from "../../../Core/rates";
import { getReserveUsdPrice } from "../../../Core/reservePrice";
import { Market } from "../../../Model/Market";
import { RateId } from "../../../Model/Rates";
import { Reserve } from "../../../Model/Reserve";
import { RESERVES_QUERY } from "../../../Query/reserve";
import { Prices } from "../../../Context/usePrices";

const LIVE_RATES = Rates.filter((rate) => rate.isLive);

export const useReserves = (args: {
  ethUsdPrice: number | undefined;
  prices: Prices | undefined;
  market: Market;
}) => {
  const [orderBy, setOrderBy] = useState<RateId>("totalLiquidityUsd");
  const [orderDirection, setOrderDirection] = useState<"desc" | "asc">("desc");

  const { ethUsdPrice, prices, market } = args;

  const { error, loading, data, refetch } = useQuery<{ reserves: Reserve[] }>(
    RESERVES_QUERY,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const reserves =
    data &&
    data.reserves
      .filter(
        (r) =>
          !r.symbol.includes("Uni") &&
          !r.symbol.includes("UST") &&
          !r.symbol.includes("AAVE") &&
          !r.symbol.includes("FEI") &&
          !r.symbol.includes("RAI") &&
          !r.symbol.includes("Amm")
      )
      .map((reserve) => ({
        reserve,
        rates: LIVE_RATES.map((rate) => {
          const { usdPrice } = getReserveUsdPrice({
            reserve,
            market,
            ethUsdPrice: ethUsdPrice || 0,
            prices,
          });

          const value = rate.rescale(reserve[rate.value], {
            reserve,
            usdPrice,
            anchorValue: 10 ** 27,
          });

          return {
            rate,
            value,
          };
        }),
      }))
      .sort((a, b) =>
        orderDirection === "desc"
          ? (b.rates.find(({ rate }) => rate.id === orderBy)?.value || 0) -
            (a.rates.find(({ rate }) => rate.id === orderBy)?.value || 0)
          : (a.rates.find(({ rate }) => rate.id === orderBy)?.value || 0) -
            (b.rates.find(({ rate }) => rate.id === orderBy)?.value || 0)
      );

  return {
    error,
    refetch,
    loading,
    setOrderDirection,
    setOrderBy,
    reserves,
    orderBy,
    orderDirection,
  };
};
