import React from "react";

export const Frame = (props: {
  children?: React.ReactNode;
  width: number;
  height: number;
}) => {
  return (
    <div
      className="relative fade-in"
      style={{ width: props.width + "px", height: props.height + "px" }}
    >
      {props.children}
    </div>
  );
};
