import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import { Container } from "../../Components/Container";
import { LineGraph } from "../../Components/LineGraph/LineGraph";
import { PERIODS } from "../../Config/constants";
import { IS_MOBILE } from "../../Config/mobile";
import { Rates } from "../../Core/rates";
import { CanvasPoint } from "../../Model/graph";
import { Market } from "../../Model/Market";
import { Reserve } from "../../Model/Reserve";
import { useCalculatedMetrics } from "./hooks/useCalculatedMetrics";
import { useDownloadHistoricalRatesCsv } from "./hooks/useDownloadHistoricalRatesCsv";
import { useReserveHistoryData } from "./hooks/useReserveHistoryData";
import { useReserveHistoryQuery } from "./hooks/useReserveHistoryQuery";
import {Prices} from "../../Context/usePrices";

dayjs.extend(relativeTime);

/*
 * Use smaller graph dimensions for smaller screens
 */
const CHART_WIDTH = window.innerWidth < 1100 ? window.innerWidth - 32 : 1100 - 32;
const CHART_HEIGHT = IS_MOBILE ? 225 : 375;
const ACTIVE_PERIODS = IS_MOBILE ? PERIODS.filter((_, i) => i % 2) : PERIODS;

export const ReserveGraph = (props: {
  selectedReserveId: string;
  selectedReserve: Reserve | undefined;
  ethUsdPrice: number | undefined;
  prices: Prices | undefined;
  market: Market;
}) => {
  const { selectedReserveId, selectedReserve, ethUsdPrice, prices, market } = props;

  const [selectedRate, setSelectedRate] = useState(Rates[1]);
  const [selectedPeriod, setSelectedPeriod] = useState(ACTIVE_PERIODS[1]);
  const [activeValue, setActiveValue] = useState<CanvasPoint>();

  const historyQuery = useReserveHistoryQuery({
    selectedRate,
    selectedPeriod,
    selectedReserveId,
  });

  const { line, anchorValue } = useReserveHistoryData({
    selectedRate,
    selectedReserve,
    selectedReserveId,
    historyQuery,
    market,
  });

  const { currentAmount } = useCalculatedMetrics({
    selectedRate,
    anchorValue,
    selectedReserve,
    ethUsdPrice,
    prices,
    market,
  });

  const downloadCsv = useDownloadHistoricalRatesCsv({
    selectedRate,
    selectedReserve,
    selectedPeriod,
    market,
    line,
  });

  useEffect(() => {
    setActiveValue(undefined);
  }, [
    selectedReserveId,
    selectedRate,
    selectedPeriod,
    historyQuery.loading,
    line,
  ]);

  const legendAmount = activeValue ? activeValue.price : currentAmount;

  const legendAmountString =
    legendAmount !== undefined && legendAmount !== 0
      ? selectedRate.toString(legendAmount)
      : "";

  return (
    <div>
      <div className="flex flex-wrap align-items-center space-between">
        <select
          className="mb8"
          onChange={(e) =>
            setSelectedRate(
              Rates.find((rate) => rate.label === e.target.value) || Rates[0]
            )
          }
          value={selectedRate.label}
        >
          {Rates.filter((rate) => rate.isHistorical)
            .filter(
              (rate) =>
                !selectedReserve ||
                rate.isThisReserveHistorical(selectedReserve)
            )
            .map((rate) => (
              <option key={rate.label} value={rate.label}>
                {rate.label}
              </option>
            ))}
        </select>
        <div className="desktop-only">
          <div className="Legend">
            <span className="attribute Legend_date">
              {activeValue &&
                dayjs(activeValue.unix).format(selectedPeriod.scrubFormat)}
            </span>
            <span className="Legend_value">{legendAmountString}</span>
          </div>
        </div>
      </div>
      <div className="ml8 my8">
        <Container
          minHeight={CHART_HEIGHT}
          loading={historyQuery.loading}
          spinner
          loadingMessage={`Loading ${selectedRate.label.toLowerCase()}...`}
          refetch={() => historyQuery.refetch()}
          error={historyQuery.error}
          noData={line && !line.values.some((v) => v.amount > 0)}
          noDataMessage={`No ${selectedRate.label.toLowerCase()} found for ${
            selectedReserve?.symbol
          }`}
        >
          {line && (
            <LineGraph
              lines={[line]}
              width={CHART_WIDTH}
              height={CHART_HEIGHT}
              period={selectedPeriod}
              activeValue={activeValue}
              setActiveValue={setActiveValue}
            />
          )}
        </Container>
      </div>
      <div className="flex flex-column-gap-6">
        {ACTIVE_PERIODS.map((period) => (
          <button
            key={period.days}
            className="my8 non-select"
            onClick={() => setSelectedPeriod(period)}
            disabled={period === selectedPeriod}
          >
            {period.title}
          </button>
        ))}
        <button onClick={downloadCsv}>
          <span>Download CSV</span>
          <FontAwesomeIcon icon={faDownload} />
        </button>
      </div>
      <p className="my16 small-font attribute">
        {selectedRate.historicalDescription}
      </p>
    </div>
  );
};
