import { Prices } from "../../../Context/usePrices";
import { getReserveUsdPrice } from "../../../Core/reservePrice";
import { Market } from "../../../Model/Market";
import { Rate } from "../../../Model/Rates";
import { Reserve } from "../../../Model/Reserve";
import { ReserveParamsHistoryItem } from "../../../Model/ReserveParamsHistoryItem";

export const useCalculatedMetrics = (args: {
  selectedReserve: Reserve | undefined;
  selectedRate: Rate;
  anchorValue: ReserveParamsHistoryItem | undefined;
  ethUsdPrice: number | undefined;
  prices: Prices | undefined;
  market: Market;
}) => {
  const {
    selectedReserve,
    anchorValue,
    selectedRate,
    ethUsdPrice,
    prices,
    market,
  } = args;

  let currentAmount: number | undefined = undefined;

  if (
    selectedReserve &&
    anchorValue !== undefined &&
    anchorValue[selectedRate.value] !== undefined
  ) {
    const currentValue = selectedReserve[selectedRate.value];

    const { usdPrice } = getReserveUsdPrice({
      reserve: selectedReserve,
      market,
      ethUsdPrice: ethUsdPrice || 0,
      prices,
    });

    currentAmount = selectedRate.rescale(currentValue, {
      reserve: selectedReserve,
      usdPrice,
      anchorValue: anchorValue[selectedRate.value],
    });
  }

  return { currentAmount };
};
