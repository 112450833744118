import { gql } from "@apollo/client";
import { getTimestampsForPeriod } from "../Core/timestampUtils";
import { Period } from "../Model/graph";
import { Rate } from "../Model/Rates";

/*
 * Historical data is returned for every block,
 * but we don't need the data to be that granular for our timeseries graph
 *
 * Use a target timestamps array to retrieve a sample of the historical data
 */
export const getReserveParamsHistoryQuery = (period: Period, rate: Rate) => {
  const timestamps = getTimestampsForPeriod(period);
  let query = "query($reserve: String!) {";

  timestamps.forEach((timestamp) => {
    query += "\n";
    query += `
      t${timestamp}: reserveParamsHistoryItems(
          first: 1, 
          where: { 
            reserve: $reserve, 
            timestamp_gt: ${timestamp}, 
            timestamp_lt: ${timestamp + 8800} 
          }
        ) 
      {
        ${rate.value},
        priceInUsd
        timestamp
      }
    `;
  });

  query += "}";

  return gql`
    ${query}
  `;
};
