import { Rate } from "../Model/Rates";
import { Reserve } from "../Model/Reserve";
import { decimalRescale } from "./decimalRescale";
import { numberToShortString, numberToString } from "./numberUtils";

export const Rates: Rate[] = [
  {
    id: "liquidityRate",
    label: "Deposit rate",
    shortLabel: "Deposit APR",
    value: "liquidityRate",
    columnWidth: 80,
    rescale: (value: number) => value * 100 * (1 / 10 ** 27),
    toShortString: (value: number) => `${numberToShortString(value)}%`,
    toString: (value: number) => `${numberToString(value)}%`,
    valueLabelSuffix: "%",
    isLive: true,
    isHistorical: true,
    isThisReserveHistorical: () => true,
    historicalDescription:
      "Deposit rate is the base yield awarded to depositors per second as an annual percentage rate (APR). This doesn't include the fees awarded to depositors from flash loans. See deposit yield for actual historical interest.",
  },
  {
    id: "variableBorrowRate",
    label: "Variable borrow rate",
    shortLabel: "Borrow APR",
    value: "variableBorrowRate",
    columnWidth: 80,
    rescale: (value: number) => value * 100 * (1 / 10 ** 27),
    toShortString: (value: number) => `${numberToShortString(value)}%`,
    toString: (value: number) => `${numberToString(value)}%`,
    valueLabelSuffix: "%",
    isLive: true,
    isHistorical: true,
    isThisReserveHistorical: () => true,
  },
  {
    id: "stableBorrowRate",
    label: "Stable borrow rate",
    shortLabel: "Stable APR",
    value: "stableBorrowRate",
    columnWidth: 80,
    rescale: (value: number) => value * 100 * (1 / 10 ** 27),
    toShortString: (value: number) =>
      value > 0 ? `${numberToShortString(value)}%` : "",
    toString: (value: number) => (value > 0 ? `${numberToString(value)}%` : ""),
    valueLabelSuffix: "%",
    isLive: false,
    isHistorical: true,
    isThisReserveHistorical: (reserve: Reserve) => reserve.stableBorrowRate > 0,
  },
  {
    id: "totalLiquidity",
    label: "Total supplied",
    shortLabel: "Supplied",
    value: "totalLiquidity",
    columnWidth: 65,
    rescale: (value: number, config: { reserve: Reserve }) =>
      value * (1 / decimalRescale(config.reserve)),
    toShortString: (value: number) => `${numberToShortString(value)}`,
    toString: (value: number) => `${numberToString(value)}`,
    isLive: true,
    isHistorical: true,
    isThisReserveHistorical: () => true,
  },
  {
    id: "totalCurrentVariableDebt",
    label: "Total borrows",
    shortLabel: "Borrowed",
    value: "totalCurrentVariableDebt",
    columnWidth: 65,
    rescale: (value: number, config: { reserve: Reserve }) =>
      value * (1 / decimalRescale(config.reserve)),
    toShortString: (value: number) => `${numberToShortString(value)}`,
    toString: (value: number) => `${numberToString(value)}`,
    isLive: true,
    isHistorical: true,
    isThisReserveHistorical: () => true,
    historicalDescription: "Total borrows/debt on a variable rate.",
  },
  {
    id: "availableLiquidity",
    label: "Available liquidity",
    shortLabel: "Liquidity",
    value: "availableLiquidity",
    columnWidth: 86,
    rescale: (value: number, config: { reserve: Reserve }) =>
      value * (1 / decimalRescale(config.reserve)),
    toShortString: (value: number) => `${numberToShortString(value)}`,
    toString: (value: number) => `${numberToString(value)}`,
    isLive: false,
    isHistorical: true,
    isThisReserveHistorical: () => true,
    historicalDescription:
      "Available liquidity is liquidity that is available to borrow.",
  },
  {
    id: "utilizationRate",
    label: "Utilization rate",
    shortLabel: "Utilization",
    value: "utilizationRate",
    columnWidth: 98,
    rescale: (value: number) => value * 100,
    toShortString: (value: number) => `${numberToShortString(value)}%`,
    toString: (value: number) => `${numberToString(value)}%`,
    valueLabelSuffix: "%",
    isLive: true,
    isHistorical: true,
    isThisReserveHistorical: () => true,
  },
  {
    id: "liquidityIndex",
    label: "Deposit yield",
    shortLabel: "Lifetime yield",
    value: "liquidityIndex",
    columnWidth: 118,
    rescale: (
      value: number,
      config: { reserve: Reserve; anchorValue: number }
    ) => {
      if (!config.anchorValue) {
        throw new Error("Anchor value is missing");
      }
      return (value / config.anchorValue) * 100 - 100;
    },
    toShortString: (value: number) => `${numberToShortString(value)}%`,
    toString: (value: number) => `${numberToString(value)}%`,
    valueLabelSuffix: "%",
    isLive: false,
    isHistorical: true,
    isThisReserveHistorical: () => true,
    historicalDescription:
      "Deposit yield (liquidity index) shows the interest earned on deposits within the selected time period. Includes flash loan fees awarded to depositors.",
    liveDescription: "Lifetime deposit interest accrued",
  },
  {
    id: "totalLiquidityUsd",
    label: "Total supplied ($)",
    shortLabel: "Supplied ($)",
    value: "totalLiquidity",
    columnWidth: 90,
    rescale: (
      value: number,
      config: {
        reserve: Reserve;
        usdPrice: number | undefined;
      }
    ) => {
      return (
        value * (config.usdPrice || 0) * (1 / decimalRescale(config.reserve))
      );
    },
    toShortString: (value: number) =>
      value ? `$${numberToShortString(value)}` : "",
    toString: (value: number) => `$${numberToString(value)}`,
    valueLabelPrefix: "$",
    isLive: true,
    isHistorical: false,
    isThisReserveHistorical: () => false,
    historicalDescription: "Total USD value of deposits.",
  },
  {
    id: "totalCurrentVariableDebtUsd",
    label: "Total borrows ($)",
    shortLabel: "Borrowed ($)",
    value: "totalCurrentVariableDebt",
    columnWidth: 90,
    rescale: (
      value: number,
      config: { reserve: Reserve; usdPrice: number | undefined }
    ) => value * (config.usdPrice || 0) * (1 / decimalRescale(config.reserve)),
    toShortString: (value: number) => `$${numberToShortString(value)}`,
    toString: (value: number) => `$${numberToString(value)}`,
    isLive: true,
    isHistorical: false,
    isThisReserveHistorical: () => false,
    historicalDescription:
      "Total USD value of borrows/debt on the variable rate ($)",
  },
];
