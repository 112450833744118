import { ApolloError } from "@apollo/client";
import { Loader } from "../Loader";

export const Container = (props: {
  loading?: boolean;
  loadingMessage?: string;
  spinner?: boolean;
  error?: ApolloError;
  noData?: boolean;
  noDataMessage?: string;
  refetch?: () => void;
  minWidth?: number;
  minHeight?: number;
  children?: React.ReactNode;
}) => {
  const active = props.error || props.loading || props.noData;

  return active ? (
    <div
      className="attribute"
      style={{
        minWidth: props.minWidth ? props.minWidth + "px" : undefined,
        minHeight: props.minHeight ? props.minHeight + "px" : undefined,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      {props.loading &&
        (props.spinner ? (
          <div style={{ paddingTop: "48px" }}>
            <Loader />
          </div>
        ) : (
          <div>{props.loadingMessage || "loading..."}</div>
        ))}
      {props.error && (
        <>
          <div className="p32">
            <div>Error: {props.error?.message || "an error occurred"}</div>
            {props.refetch && (
              <div className="text-center my8">
                <button onClick={props.refetch}>try again</button>
              </div>
            )}
          </div>
        </>
      )}
      {props.noData && <div>{props.noDataMessage || "no data"}</div>}
    </div>
  ) : (
    <div
      style={{
        minWidth: props.minWidth ? props.minWidth + "px" : undefined,
        minHeight: props.minHeight ? props.minHeight + "px" : undefined,
      }}
    >
      {props.children}
    </div>
  );
};
