import { Link } from "react-router-dom";
import { useTheme } from "../../Context/useTheme";
import { MARKETS } from "../../Core/markets";

function capitaliseFirstLetter(v: string) {
  return v.charAt(0).toUpperCase() + v.slice(1);
}

export const Footer = () => {
  const { otherTheme, toggleTheme } = useTheme();

  return (
    <footer>
      <h2>Aavescan</h2>
      <p className="attribute small-font">
        Live and historical rates for Aave.
      </p>
      <div className="attribute mt16 Footer_row">
        <button className="LinkButton" onClick={toggleTheme}>
          {otherTheme} mode
        </button>
        <a href="https://pro.aavescan.com">Aavescan Pro</a>
        <Link to="/about">About</Link>
        <a href="https://twitter.com/geeogi">Contact</a>
        <a href="https://pro.aavescan.com/terms">Terms</a>
        {MARKETS.map((market) => (
          <Link to={`/${market.id}`}>{capitaliseFirstLetter(market.name)}</Link>
        ))}
        <a href="https://u--1.com">EigenLayer | u--1</a>
      </div>
    </footer>
  );
};
