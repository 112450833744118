import { Period } from "../Model/graph";

export const UNIX_HOUR = 3600000;

export const PERIODS: Period[] = [
  {
    value: "1",
    days: 1,
    title: "1d",
    step: { multiplier: 6, unit: "h", max: 3 },
    labelFormat: "HH:mm",
    scrubFormat: "HH:mm D MMM",
  },
  {
    value: "7",
    days: 7,
    title: "1w",
    step: { multiplier: 2, unit: "d", max: 3 },
    labelFormat: "D MMM",
    scrubFormat: "HH:mm D MMM",
  },
  {
    value: "30",
    days: 30,
    title: "1m",
    step: { multiplier: 1, unit: "w", max: 4 },
    labelFormat: "D MMM",
    scrubFormat: "HH:mm D MMM",
  },
  {
    value: "90",
    days: 90,
    title: "3m",
    step: { multiplier: 3, unit: "w", max: 3 },
    labelFormat: "D MMM",
    scrubFormat: "HH:mm D MMM",
  },
  {
    value: "180",
    days: 180,
    title: "6m",
    step: { multiplier: 7, unit: "w", max: 3 },
    labelFormat: "D MMM",
    scrubFormat: "D MMM YYYY",
  },
  {
    value: "360",
    days: 360,
    title: "1y",
    step: { multiplier: 14, unit: "w", max: 3 },
    labelFormat: "D MMM",
    scrubFormat: "D MMM YYYY",
  },
];

export const COIN_SYMBOL_TO_CMC_ID = {
  USDT: 825,
  USDt: 825,
  USDC: 3408,
  DAI: 4943,
  SUSD: 2927,
  YFI: 5864,
  BUSD: 4687,
  CVX: 9903,
  RAI: 8525,
  STETH: 8085,
  ENS: 13855,
  sUSD: 2927,
  EURS: 2989,
  TUSD: 2563,
  SNX: 2586,
  BAT: 1697,
  MKR: 1518,
  REN: 2539,
  ZRX: 1896,
  FRAX: 6952,
  ETH: 1027,
  MANA: 1966,
  WBTC: 3717,
  LINK: 1975,
  KNC: 1982,
  ENJ: 2130,
  GUSD: 3306,
  CRV: 6538,
  WETH: 2396,
  UNI: 7083,
  AAVE: 7278,
  LEND: 7278,
  REP: 1104,
  UniDAI: 7083,
  UniUSDC: 7083,
  BAL: 5728,
  XSUSHI: 6758,
  SUSHI: 6758,
  FEI: 8642,
  WMATIC: 8925,
  miMATIC: 10238,
  WAVAX: 5805,
  LUSD: 9566,
  RENFIL: 7997,
  AMPL: 4056,
  "1INCH": 8104,
  DPI: 7055,
  PAX: 3330,
  GHST: 7046,
  agEUR: 15024,
  MaticX: 19923,
  stMATIC: 18688,
  MAI: 10238,
  sAVAX: 18523,
  BTC: 1,
  cbETH: 21535,
  wstETH: 12409,
  LDO: 8000,
  GHO: 7278,
  rETH: 15060,
  OP: 11840,
};

export const RATION_CSV_ADVERT =
  "Data provided by Aavescan Pro. Visit https://pro.aavescan.com to fetch up to 2 years of historical daily rates.";
