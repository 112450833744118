import { gql } from "@apollo/client";

export const RESERVES_QUERY = gql`
  query {
    reserves(first: 100) {
      id
      name
      decimals
      symbol
      liquidityRate
      variableBorrowRate
      stableBorrowRate
      totalLiquidity
      utilizationRate
      availableLiquidity
      liquidityIndex
      totalCurrentVariableDebt
      price {
        priceInEth
      }
    }
  }
`;

export const RESERVE_QUERY = gql`
  query ($id: String!) {
    reserve(id: $id) {
      id
      name
      decimals
      symbol
      liquidityRate
      variableBorrowRate
      stableBorrowRate
      totalLiquidity
      utilizationRate
      availableLiquidity
      liquidityIndex
      totalCurrentVariableDebt
      price {
        priceInEth
      }
    }
  }
`;
