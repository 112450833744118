import { useNavigate } from "react-router-dom";
import { MARKETS } from "../../Core/markets";
import { useMarket } from "../../hooks/useMarket";

export const Header = () => {
  const navigate = useNavigate();

  const { market } = useMarket();

  return (
    <>
      <h1
        className="text-center"
        role="button"
        onClick={
          MARKETS[0].id === market.id
            ? () => navigate("/")
            : () => navigate(`/${market.id}`)
        }
      >
        <img src="/ghost.svg" alt="aave" />
        Aavescan
      </h1>
      <p className="attribute small-font text-center desktop-only">
        Live and historical Aave rates, powered by{" "}
        <a
          href="https://pro.aavescan.com"
          target="_blank"
          rel="noreferrer"
          className="secondary-link"
        >
          Aavescan Pro
        </a>
      </p>
    </>
  );
};
