import dayjs from "dayjs";
import React from "react";
import { getCoordinatesOfMouseEvent } from "../../Core/domUtils";
import { getGraphConfig, sample } from "../../Core/graphUtils";
import { numberWithSignificantDigits } from "../../Core/numberUtils";
import { CanvasPoint, Line, Period } from "../../Model/graph";
import { ActiveCircle } from "./ActiveCircle";
import { ActiveLine } from "./ActiveLine";
import { Area } from "./Area";
import { Frame } from "./Frame";
import { Gradient } from "./Gradient";
import { HorizontalGridLine } from "./HorizontalGridLine";
import { Label } from "./Label";
import { VerticalGridLine } from "./VerticalGridLine";

export const LineGraph = (props: {
  lines: Line[];
  period: Period;
  width: number;
  height: number;
  activeValue: CanvasPoint | undefined;
  setActiveValue: (point: CanvasPoint | undefined) => void;
}) => {
  const { lines, width, period, activeValue, setActiveValue } = props;

  const height = props.height - 24;

  // Sample values to achieve ~1 point per pixel
  const sampledLines = lines.map((line) => ({
    ...line,
    values: sample(line.values, width),
  }));

  // Configure graph values
  const { paths, dateLabels, priceLabels, scalePriceY, scaleUnixX } =
    getGraphConfig({ lines: sampledLines, period });

  // Utils to convert from clip space [-1,1] to graph coordinates [x,y]
  const toGraphX = (x: number) => ((x + 1) / 2) * width;
  const toGraphY = (y: number) => ((y + 1) / 2.2) * height;

  // Utils to convert from graph coordinates [x,y] to canvas pixels [x,^y]
  const toCanvasX = (graphX: number) => graphX;
  const toCanvasY = (graphY: number) => height - graphY;

  // Scale graph coordinates from clip space [-1,1] to screen resolution
  const scaledPaths = paths.map((path) =>
    path.map((point) => ({
      canvasX: toCanvasX(toGraphX(point.x)),
      canvasY: toCanvasY(toGraphY(point.y)),
      ...point,
    }))
  );

  // Define labels
  const yLabels = priceLabels.map((price) => ({
    price,
    top: toCanvasY(toGraphY(scalePriceY(price))),
  }));

  const xLabels = dateLabels
    .map((unix) => ({
      unix,
      left: toCanvasX(toGraphX(scaleUnixX(unix))),
    }))
    .filter((xLabel) => width - xLabel.left > 50);

  /**
   * Interaction handler
   * @param activeX
   */
  const handleActiveX = (activeX: number) => {
    if (scaledPaths[0]) {
      // Find nearest point to activeX
      const [canvasPoint] = [...scaledPaths[0]].sort(
        (a, b) => Math.abs(a.canvasX - activeX) - Math.abs(b.canvasX - activeX)
      );

      // Set active state
      setActiveValue(canvasPoint);
    }
  };

  /**
   * Mouse handler
   */
  const handleMouse = (e: React.MouseEvent<SVGSVGElement>) => {
    handleActiveX(getCoordinatesOfMouseEvent(e).x);
  };

  /**
   * Leave handler
   */
  const handleLeave = () => {
    setActiveValue(undefined);
  };

  return (
    <div className="relative non-select" style={{ height: height + 24 }}>
      <Frame width={width} height={height}>
        {xLabels?.map(({ left, unix }) => (
          <VerticalGridLine
            left={left}
            key={`${lines[0].uniqueId}-${unix.toString()}`}
          />
        ))}
        {yLabels?.map(({ top, price }) => (
          <HorizontalGridLine
            top={top}
            key={`${lines[0].uniqueId}-${price.toString()}`}
          />
        ))}
        {yLabels?.map(({ price, top }) => (
          <Label
            key={`${lines[0].uniqueId}-${price.toString()}`}
            text={numberWithSignificantDigits(price)}
            top={top}
            left={0}
          />
        ))}
        {activeValue && (
          <>
            <ActiveLine
              left={activeValue.canvasX}
              color={lines[0].color}
              width={1}
            />
            <ActiveCircle
              size={18}
              left={activeValue.canvasX}
              top={activeValue.canvasY}
              color={lines[0].color}
            />
          </>
        )}
        {lines.map((line, index) => (
          <svg
            viewBox={`0 0 ${width} ${height}`}
            onMouseMove={handleMouse}
            onMouseLeave={handleLeave}
            onTouchEnd={handleLeave}
            onTouchCancel={handleLeave}
            key={line.uniqueId}
            className="absolute"
          >
            {line.shaded && (
              <defs>
                <Gradient uniqueId={line.uniqueId} color={line.color} />
              </defs>
            )}
            {scaledPaths[index] && (
              <Area
                uniqueId={line.uniqueId}
                height={height}
                width={width}
                points={scaledPaths[index]}
                color={line.color}
                filled={line.shaded}
              />
            )}
          </svg>
        ))}
      </Frame>
      {xLabels?.map(({ unix, left }) => (
        <Label
          key={`${lines[0].uniqueId}-${unix.toString()}`}
          text={dayjs(unix).format(period.labelFormat)}
          top={height}
          left={left}
        />
      ))}
    </div>
  );
};
