import { Reserve } from "../../../Model/Reserve";

export const useRedirectOnError = (args: {
  reserveId: string | undefined;
  data: { reserve: Reserve } | undefined;
}) => {
  const { reserveId, data } = args;

  if (!reserveId) {
    /*
     * No ID, redirect to homepage
     */
    console.log('no reserveId, redirecting')
    window.location.replace("/");
  }

  if (data && !data.reserve) {
    /*
     * Can't find the reserve data for this ID, redirect to homepage
     */
    console.log('no reserve data, redirecting')
    window.location.replace("/");
  }
};
