import { useScrollToTop } from "../../hooks/useScrollToTop";
import { AboutHelmet } from "./AboutHelmet";

export const AboutPage = () => {
  useScrollToTop();

  return (
    <div style={{ maxWidth: "900px" }} className="mauto">
      <AboutHelmet />
      <h2 className="my16">Aave</h2>
      <p>
        Aave is an open source and non-custodial liquidity protocol for earning
        interest on deposits and borrowing assets.
      </p>
      <h2 className="my16">Aavescan</h2>
      <p>
        Aavescan is an analytics platform for Aave that shows live and
        historical lending data including deposit rates, borrow rates and
        utilization. Researchers and investors use Aavescan to gain insight into
        Aave markets on Ethereum, Polygon, Arbitrum, Optimism and Avalanche.
      </p>
      <h2 className="my16">What is the deposit rate? (liquidityRate)</h2>
      <p className="mt16">
        The deposit rate is the base yield awarded to depositors per second as
        an annual percentage rate (APR). This doesn't include the fees awarded
        to depositors from flash loans. See deposit yield for actual historical
        interest.
      </p>
      <h2 className="my16">
        What is the variable borrow rate? (variableBorrowRate)
      </h2>
      <p className="mt16">
        The variable borrow rate is the rate at which interest accrues for
        borrowers per second as an annual percentage rate (APR).
      </p>
      <h2 className="my16">What is the market size?</h2>
      <p className="mt16">
        The market size is the total amount of the reserve asset deposited into
        the lending pool, in native or USD terms.
      </p>
      <h2 className="my16">What is liquidity?</h2>
      <p className="mt16">
        Liquidity is the total amount of the reserve asset available to borrow
        from the lending pool in native terms.
      </p>
      <h2 className="my16">What is utilization?</h2>
      <p className="mt16">
        Utilization is the percentage of the reserve asset which has been
        borrowed out of the pool. A high utilization rate implies a high amount
        of borrowing and high interest rates.
      </p>
      <h2 className="my16">What is deposit yield? (liquidityIndex)</h2>
      <p className="mt16">
        Deposit yield is the actual interest earned on deposits within a
        historical time period. The index captures fluctuations in the deposit
        rate and includes compound interest and flash loan fees awarded to
        depositors.
      </p>
      <h2 className="my16">What is the lifetime yield?</h2>
      <p className="mt16">
        The lifetime yield is the total interest earned by depositors since the
        deployment of the pool. Like the deposit yield, this metric captures
        fluctuations in the deposit rate and includes compound interest and
        flash loan fees awarded to depositors.
      </p>
      <h2 className="my16">Disclaimer</h2>
      <p className="mt16">
        In general historical rates do not imply future rates. This site does
        not offer any investment advice. This site is in development and data
        shown here may be inaccurate. This site does not use cookies.
      </p>
      <h2 className="my16">Aavescan Pro</h2>
      <p className="mt16 mb32">
        Get up to 3 years of historical data for Aave, Compound and Euler on{" "}
        <a href="https://pro.aavescan.com">Aavescan Pro</a>. Aavescan is built
        by RationAnalytics.
      </p>
    </div>
  );
};
