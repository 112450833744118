import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Period } from "../../../Model/graph";
import { Rate } from "../../../Model/Rates";
import { ReserveParamsHistoryItem } from "../../../Model/ReserveParamsHistoryItem";
import { getReserveParamsHistoryQuery } from "../../../Query/reserveParamsHistory";

export const useReserveHistoryQuery = (args: {
  selectedReserveId: string;
  selectedRate: Rate;
  selectedPeriod: Period;
}) => {
  const { selectedPeriod, selectedRate, selectedReserveId } = args;

  /*
   * Recalculate reserve history query sparingly
   */
  const query = useMemo(
    () => getReserveParamsHistoryQuery(selectedPeriod, selectedRate),
    [selectedPeriod, selectedRate]
  );

  return useQuery<{
    [key: string]: ReserveParamsHistoryItem;
  }>(query, {
    variables: { reserve: selectedReserveId },
    notifyOnNetworkStatusChange: true,
  });
};
