import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { AssetImage } from "../../Components/AssetImage";
import { Container } from "../../Components/Container";
import { IS_MOBILE } from "../../Config/mobile";
import { useMarket } from "../../hooks/useMarket";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import { useRedirectOnError } from "./hooks/useRedirectOnError";
import { useSelectedReserveQuery } from "./hooks/useSelectedReserveQuery";
import { ReserveGraph } from "./ReserveGraph";
import { ReserveHelmet } from "./ReserveHelmet";
import {Prices} from "../../Context/usePrices";

export const ReservePage = (props: { ethUsdPrice: number | undefined , prices: Prices | undefined}) => {
  const { ethUsdPrice, prices } = props;

  const { market } = useMarket();

  const { reserveId } = useParams();

  const { error, data, refetch, selectedReserve } =
    useSelectedReserveQuery(reserveId);

  useRedirectOnError({ data, reserveId });

  useScrollToTop();

  return (
    <Container error={error} refetch={() => refetch()} minHeight={300}>
      {reserveId && (
        <>
          <ReserveHelmet selectedReserve={selectedReserve} market={market} />
          <h2 className="mt16 flex align-items-center">
            {selectedReserve && (
              <span className="mr4">
                <AssetImage symbol={selectedReserve.symbol} size={16} />
              </span>
            )}
            <span>
              {selectedReserve
                ? IS_MOBILE
                  ? selectedReserve.symbol
                  : selectedReserve.name
                : "..."}{" "}
            </span>
          </h2>
          <p className="mb16 attribute small-font">
            {market.name} ({reserveId?.slice(0, 12)})
          </p>
          <ReserveGraph
            selectedReserveId={reserveId}
            selectedReserve={selectedReserve}
            ethUsdPrice={ethUsdPrice}
            prices={prices}
            market={market}
          />
          <div className="AavescanProAdvert mt16">
            <a href="https://pro.aavescan.com" target="_blank" rel="noreferrer">
              <span>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </span>
              <span className="ml6">
                Get 3 years historical data on Aavescan Pro
              </span>
            </a>
          </div>
        </>
      )}
    </Container>
  );
};
