import { HomePageSelect } from "./HomePageMarketSelect";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import { useReserves } from "./hooks/useReserves";
import { HomePageTable } from "./HomePageTable";
import { HomePageHelmet } from "./HomePageHelmet";
import { useMarket } from "../../hooks/useMarket";
import { Prices } from "../../Context/usePrices";

export const HomePage = (props: {
  ethUsdPrice: number | undefined;
  prices: Prices | undefined;
}) => {
  const { market } = useMarket();

  const {
    error,
    refetch,
    loading,
    reserves,
    setOrderBy,
    setOrderDirection,
    orderBy,
    orderDirection,
  } = useReserves({
    ethUsdPrice: props.ethUsdPrice,
    market,
    prices: props.prices,
  });

  useScrollToTop();

  return (
    <>
      <HomePageHelmet market={market} />
      <HomePageSelect market={market} />
      <HomePageTable
        reserves={reserves}
        error={error}
        refetch={refetch}
        loading={loading}
        market={market}
        orderBy={orderBy}
        orderDirection={orderDirection}
        setOrderBy={setOrderBy}
        toggleOrderDirection={() =>
          setOrderDirection(orderDirection === "asc" ? "desc" : "asc")
        }
      />
    </>
  );
};
