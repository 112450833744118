import { RATION_CSV_ADVERT } from "../../../Config/constants";
import { Market } from "../../../Model/Market";
import { Reserve } from "../../../Model/Reserve";
import { useReserves } from "./useReserves";

export const useDownloadReserveListCsv = (args: {
  reserves: ReturnType<typeof useReserves>["reserves"];
  market: Market;
}) => {
  const { reserves, market } = args;

  const headers = reserves
    ? [
        ...["id", "name", "symbol", "decimals"].map((value) => ({
          value,
          type: "metadata",
        })),
        ...reserves[0].rates
          .map(({ rate }) => rate.shortLabel)
          .map((value) => ({ value, type: "rate" })),
      ]
    : undefined;

  return reserves && headers
    ? () => {
        const rows = [
          headers.map(({ value }) => value),
          ...reserves.map(({ reserve, rates }) => [
            ...headers.map((header) => {
              if (header.type === "metadata") {
                return reserve[header.value as keyof Reserve];
              } else {
                const item = rates.find(
                  ({ rate }) => rate.shortLabel === header.value
                );
                return item ? Math.max(item.value, 0) : "";
              }
            }),
          ]),
          [RATION_CSV_ADVERT],
        ];

        let csvContent = "data:text/csv;charset=utf-8,";

        rows.forEach(function (rowArray) {
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute(
          "download",
          `reserves-${market.id}-aavescan-${new Date().toISOString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
      }
    : undefined;
};
