import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Old URL:
//
// https://aavescan.com/reserve/0x5f98805a4e8be255a32880fdec7f6728c6568ba00xb53c1a33016b2dc2ff3653530bff1848a515c8c5?market=ethereum-v2
//
// Older URL (v2 is ethereum-v2):
//
// https://aavescan.com/reserve/0x6b175474e89094c44da98b954eedeac495271d0f0xb53c1a33016b2dc2ff3653530bff1848a515c8c5?version=v2

export function Redirect() {
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const version = searchParams.get("version");

    const market = version ? "ethereum-v2" : searchParams.get("market");

    const reserveId = window.location.pathname.split("/")[2];

    // Old URLs have a market or a version param
    if (searchParams.get("version") || searchParams.get("market")) {
      if (market && reserveId) {
        navigate(`/${market}/${reserveId}`, { replace: true });
      } else if (market) {
        navigate(`/${market}`, { replace: true });
      }
    }
  }, [navigate]);

  return null;
}
