import { GraphPoint, Period, Line } from "../Model/graph";
import { getDateLabels, getPriceLabels } from "./labelUtils";
import { getScaleMethod } from "./numberUtils";

/**
 * Determines graph coordinates and axis labels
 * @param args
 */
export const getGraphConfig = (args: { lines: Line[]; period: Period }) => {
  // Determine the base for the config
  const { lines, period } = args;

  // Calculate min, max and average price
  const minPrice = Math.min(
    ...lines.flatMap((line) => line.values.map((value) => value.amount))
  );
  const maxPrice = Math.max(
    ...lines.flatMap((line) => line.values.map((value) => value.amount))
  );

  // Calculate min, max date
  const primaryLine = lines[0];
  const earliestDate = primaryLine.values[0].unix;
  const latestDate = primaryLine.values[primaryLine.values.length - 1].unix;

  // Configure x-axis labels
  const dateLabels = getDateLabels(
    primaryLine.values.map(({ unix }) => unix),
    period
  );

  // Configure y-axis labels
  const priceLabels = getPriceLabels(
    primaryLine.values.map(({ amount: price }) => price)
  );

  // Configure x-axis scale helpers
  const unixMin = earliestDate;
  const unixMax = latestDate;
  const scaleUnixX = getScaleMethod(unixMin, unixMax, -1, 1);

  // Configure y-axis scale helpers
  const scalePriceY = getScaleMethod(minPrice, maxPrice, -0.8, 1);

  // Calculate point coordinates [-1,1]
  const paths: GraphPoint[][] = lines.map((line) =>
    line.values.map((value) => ({
      x: scaleUnixX(value.unix),
      y: scalePriceY(value.amount),
      price: value.amount,
      unix: value.unix,
    }))
  );

  return {
    priceLabels,
    dateLabels,
    paths,
    scalePriceY,
    scaleUnixX,
  };
};

/**
 * Sample lines to achieve ~1 point per pixel
 */
export const sample = (values: any[], sampleSize: number) =>
  values.filter(
    (_, index) => index % Math.ceil(values.length / sampleSize) === 0
  );
