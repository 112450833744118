import dayjs from "dayjs";
import { Period } from "../Model/graph";

/*
 * Returns an array of timestamps ranging across a period
 * which can be used to sample historical data
 */
export const getTimestampsForPeriod = (period: Period) => {
  const timestamps = [];
  const sampleLength = 400;

  const start = dayjs().add(-period.days, "days").unix();
  const end = dayjs().unix();
  const delta = (end - start) / sampleLength;

  for (let i = 1; i <= sampleLength; i++) {
    timestamps.push(Math.round(start + i * delta));
  }

  /*
   * Add current time so most recent block is always included
   */
  timestamps.push(dayjs().add(-5, "minutes").unix());

  return timestamps;
};
