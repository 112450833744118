import { TsHelmet } from "../../Components/TsHelmet";

export const AboutHelmet = () => {
  const title = "About Aavescan";

  const description =
    "Aavescan shows live and historical lending data for Aave including deposit rates, borrow rates and utilization. Researchers and investors use Aavescan to gain insight into Aave markets on Ethereum, Polygon, Arbitrum, Optimism and Avalanche.";

  const url = "https://aavescan.com/about";

  return (
    <TsHelmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
    </TsHelmet>
  );
};
