import { RATION_CSV_ADVERT } from "../../../Config/constants";
import { Line, Period } from "../../../Model/graph";
import { Market } from "../../../Model/Market";
import { Rate } from "../../../Model/Rates";
import { Reserve } from "../../../Model/Reserve";

export const useDownloadHistoricalRatesCsv = (args: {
  selectedReserve: Reserve | undefined;
  line: Line | undefined;
  selectedRate: Rate;
  selectedPeriod: Period;
  market: Market;
}) => {
  const { selectedReserve, line, selectedRate, market, selectedPeriod } = args;

  return line && selectedReserve
    ? () => {
        const rows = [
          ["timestamp", "date", selectedRate.label],
          ...line.values.map((value) => [
            value.unix,
            new Date(value.unix).toISOString(),
            value.amount,
          ]),
          [RATION_CSV_ADVERT],
        ];

        let csvContent = "data:text/csv;charset=utf-8,";

        rows.forEach(function (rowArray) {
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute(
          "download",
          `${selectedReserve.symbol}-${selectedPeriod.title}-${
            selectedRate.label
          }-${market.id}-aavescan-${new Date().toISOString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
      }
    : undefined;
};
