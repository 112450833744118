import { useQuery } from "@apollo/client";
import { Reserve } from "../../../Model/Reserve";
import { RESERVE_QUERY } from "../../../Query/reserve";

export const useSelectedReserveQuery = (id?: string) => {
  const { error, refetch, data, loading } = useQuery<{
    reserve: Reserve;
  }>(RESERVE_QUERY, {
    variables: { id },
    skip: !id,
    notifyOnNetworkStatusChange: true,
  });

  const selectedReserve = data?.reserve;

  return { error, refetch, loading, data, selectedReserve };
};
