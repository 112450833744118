import { TsHelmet } from "../../Components/TsHelmet";
import { Market } from "../../Model/Market";
import { Reserve } from "../../Model/Reserve";

export const ReserveHelmet = (props: {
  selectedReserve: Reserve | undefined;
  market: Market;
}) => {
  const { selectedReserve, market } = props;

  if (!selectedReserve) return <></>;

  const title = `${selectedReserve.symbol} rates on Aave ${market.name}`;

  const description = `Live and historical rates for ${selectedReserve.symbol} on Aave ${market.name} including deposit APY, borrow APR, market size and utilization.`;

  const url = `https://aavescan.com/${market.id}/${selectedReserve.id}`;

  return (
    <TsHelmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
    </TsHelmet>
  );
};
