import "./index.css";

export const Loader = () => {
  return <span className="loader"></span>;
};

export const FullWidthLoader = () => {
  return (
    <div className="FullWidthLoader fade-in">
      <Loader />
    </div>
  );
};
