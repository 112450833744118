import { ApolloProvider } from "@apollo/client";
import { Route, Routes } from "react-router-dom";
import { Footer } from "./Components/Footer";
import { Header } from "./Components/Header";
import { ThemeProvider } from "./Context/useTheme";
import { useEthPrice } from "./hooks/useEthPrice";
import { useMarket } from "./hooks/useMarket";
import { AboutPage } from "./Pages/AboutPage";
import { HomePage } from "./Pages/HomePage";
import { Redirect } from "./Pages/Redirect";
import { ReservePage } from "./Pages/ReservePage";
import { usePrices } from "./Context/usePrices";

function App() {
  const { ethUsdPrice } = useEthPrice();
  const { market } = useMarket();
  const { prices } = usePrices();

  return (
    <ThemeProvider>
      <Header />
      <main>
        <ApolloProvider client={market.client}>
          <Routes>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/reserve/:reserve" element={<Redirect />} />
            <Route
              path="/:market/:reserveId"
              element={
                <ReservePage ethUsdPrice={ethUsdPrice} prices={prices} />
              }
            />
            <Route
              path="/:market"
              element={<HomePage ethUsdPrice={ethUsdPrice} prices={prices} />}
            />
            <Route
              path="/"
              element={<HomePage ethUsdPrice={ethUsdPrice} prices={prices} />}
            />
          </Routes>
        </ApolloProvider>
      </main>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
