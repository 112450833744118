import { ApolloClient, InMemoryCache } from "@apollo/client";

const API_KEY = "a820147ae9eec25fbfa2f206671706b8";

const ethereumV2Client = new ApolloClient({
  uri: `https://gateway-arbitrum.network.thegraph.com/api/${API_KEY}/subgraphs/id/8wR23o1zkS4gpLqLNU4kG3JHYVucqGyopL5utGxP2q1N`,
  cache: new InMemoryCache(),
});

const ethereumV3Client = new ApolloClient({
  uri: `https://gateway-arbitrum.network.thegraph.com/api/${API_KEY}/subgraphs/id/Cd2gEDVeqnjBn1hSeqFMitw8Q1iiyV9FYUZkLNRcL87g`,
  cache: new InMemoryCache(),
});

const ethereumV3LidoClient = new ApolloClient({
  uri: `https://gateway.thegraph.com/api/${API_KEY}/subgraphs/id/5vxMbXRhG1oQr55MWC5j6qg78waWujx1wjeuEWDA6j3`,
  cache: new InMemoryCache(),
});

const baseV3Client = new ApolloClient({
  uri: `https://gateway-arbitrum.network.thegraph.com/api/${API_KEY}/subgraphs/id/GQFbb95cE6d8mV989mL5figjaGaKCQB3xqYrr1bRyXqF`,
  cache: new InMemoryCache(),
});

const polygonV2Client = new ApolloClient({
  uri: `https://gateway-arbitrum.network.thegraph.com/api/${API_KEY}/subgraphs/id/H1Et77RZh3XEf27vkAmJyzgCME2RSFLtDS2f4PPW6CGp`,
  cache: new InMemoryCache(),
});

const avalancheV2Client = new ApolloClient({
  uri: `https://gateway-arbitrum.network.thegraph.com/api/${API_KEY}/subgraphs/id/EZvK18pMhwiCjxwesRLTg81fP33WnR6BnZe5Cvma3H1C`,
  cache: new InMemoryCache(),
});

const arbitrumV3Client = new ApolloClient({
  uri: `https://gateway-arbitrum.network.thegraph.com/api/${API_KEY}/subgraphs/id/DLuE98kEb5pQNXAcKFQGQgfSQ57Xdou4jnVbAEqMfy3B`,
  cache: new InMemoryCache(),
});

const optimismV3Client = new ApolloClient({
  uri: `https://gateway-arbitrum.network.thegraph.com/api/${API_KEY}/subgraphs/id/DSfLz8oQBUeU5atALgUFQKMTSYV9mZAVYp4noLSXAfvb`,
  cache: new InMemoryCache(),
});

const polygonV3Client = new ApolloClient({
  uri: `https://gateway-arbitrum.network.thegraph.com/api/${API_KEY}/subgraphs/id/Co2URyXjnxaw8WqxKyVHdirq9Ahhm5vcTs4dMedAq211`,
  cache: new InMemoryCache(),
});

const avalancheV3Client = new ApolloClient({
  uri: `https://gateway-arbitrum.network.thegraph.com/api/${API_KEY}/subgraphs/id/2h9woxy8RTjHu1HJsCEnmzpPHFArU33avmUh4f71JpVn`,
  cache: new InMemoryCache(),
});

export const MARKETS = [
  {
    name: "ethereum V3",
    client: ethereumV3Client,
    id: "ethereum-v3",
    chain: "ethereum",
    baseCurrency: "usd",
    baseDecimals: 8,
  },
  {
    name: "ethereum V3 Lido",
    client: ethereumV3LidoClient,
    id: "ethereum-v3-lido",
    chain: "ethereum",
    baseCurrency: "usd",
    baseDecimals: 8,
  },
  {
    name: "ethereum V2",
    client: ethereumV2Client,
    id: "ethereum-v2",
    chain: "ethereum",
    baseCurrency: "eth",
    baseDecimals: 18,
  },
  {
    name: "base V3",
    client: baseV3Client,
    id: "base-v3",
    chain: "base",
    baseCurrency: "usd",
    baseDecimals: 8,
  },
  {
    name: "arbitrum V3",
    client: arbitrumV3Client,
    id: "arbitrum-v3",
    chain: "arbitrum",
    baseCurrency: "usd",
    baseDecimals: 8,
  },
  {
    name: "optimism V3",
    client: optimismV3Client,
    id: "optimism-v3",
    chain: "optimism",
    baseCurrency: "usd",
    baseDecimals: 8,
  },
  {
    name: "polygon V3",
    client: polygonV3Client,
    id: "polygon-v3",
    chain: "polygon",
    baseCurrency: "usd",
    baseDecimals: 8,
  },
  {
    name: "avalanche V3",
    client: avalancheV3Client,
    id: "avalanche-v3",
    chain: "avalanche",
    baseCurrency: "usd",
    baseDecimals: 8,
  },
  {
    name: "polygon V2",
    client: polygonV2Client,
    id: "polygon-v2",
    chain: "polygon",
    baseCurrency: "eth",
    baseDecimals: 18,
  },
  {
    name: "avalanche V2",
    client: avalancheV2Client,
    id: "avalanche-v2",
    chain: "avalanche",
    baseCurrency: "usd",
    baseDecimals: 8,
  },
];
