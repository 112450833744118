import { createContext, useContext, useEffect } from "react";

const ThemeContext = createContext({
  theme: "dark",
  otherTheme: "light",
  toggleTheme: () => {},
});

const lightTheme: { [key: string]: string } = {
  "--background-color": "rgb(250, 250, 250)",
  "--translucent-background-color": "rgba(240, 240, 240, 0.5)",
  "--contrast-color-1": "rgb(220, 220, 220)",
  "--contrast-color-2": "rgb(200, 200, 200)",
  "--contrast-color-3": "#333",
  "--contrast-color-4": "#343c54",
  "--contrast-color-5": "#111",
  "--accent-color": "#1652f0",
  "--chart-color": "#99559b",
  "--row-color": "#f3e4f5",
};

const darkTheme: { [key: string]: string } = {
  "--background-color": "#111111",
  "--translucent-background-color": "rgba(17, 17, 17, 0.5)",
  "--contrast-color-1": "#222222",
  "--contrast-color-2": "#333333",
  "--contrast-color-3": "#999999",
  "--contrast-color-4": "#c0c0c0",
  "--contrast-color-5": "#fafafa",
  "--accent-color": "#1f00ff",
  "--chart-color": "#d6b6d7",
  "--row-color": "#171722",
};

const getTheme = () => localStorage.getItem("theme") || "dark";

export const isDarkMode = () => getTheme() === "dark";

export const ThemeProvider = (props: { children: React.ReactNode }) => {
  const theme = getTheme();

  useEffect(() => {
    const root = window.document.documentElement;
    const themeColours = theme === "dark" ? darkTheme : lightTheme;

    Object.keys(themeColours).forEach((key) => {
      root.style.setProperty(key, themeColours[key]);
    });
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    localStorage.setItem("theme", newTheme);
    window.location.reload();
  };

  const value = {
    theme,
    toggleTheme,
    otherTheme: theme === "dark" ? "Light" : "Dark"
  };

  return (
    <ThemeContext.Provider value={value}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }

  return context;
};
