import { useNavigate } from "react-router-dom";
import { MARKETS } from "../../Core/markets";

export const HomePageSelect = (props: { market: (typeof MARKETS)[0] }) => {
  const { market } = props;

  const navigate = useNavigate();

  return (
    <select
      className="my8"
      value={MARKETS.findIndex((c) => c.name === market.name)}
      onChange={(e) => navigate(`/${MARKETS[parseInt(e.target.value)].id}`)}
    >
      {MARKETS.map((market, index) => (
        <option key={market.name} value={index}>
          {market.name}
        </option>
      ))}
    </select>
  );
};
