import { useLocation } from "react-router-dom";
import { MARKETS } from "../Core/markets";

export const useMarket = () => {
  const { pathname } = useLocation();

  const marketId = pathname.split("/")[1] || MARKETS[0].id;

  const market = MARKETS.find((item) => item.id === marketId) || MARKETS[0];

  return { market };
};
